import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import PostItem from '../components/PostItem';
import Pagination from '../components/Pagination';
import * as S from '../components/ListWrapper/styled';
import { Container } from '../styles';
import { PageHeader } from '../components/Commons';

const BlogList = (props) => {
	const postList = props.data.allMarkdownRemark.edges;
	const { currentPage, numPages } = props.pageContext;
	const isFirst = currentPage === 1;
	const isLast = currentPage === numPages;
	const prevPage = (currentPage - 1) === 1 ? '/' : `/page/${currentPage - 1}`;
	const nextPage = `/page/${currentPage + 1}`;

	return (
		<Layout>
			<SEO title="Articles" />
            <PageHeader title="Artigos" subtitle="Lorem ipsum lorem lorem lorem lorem lorem" />
            <Container>
                <S.ListWrapper>
                    {postList.map(({
                        node: {
                            fields: { slug },
                            frontmatter: { background, category, date, description, title, image },
                            timeToRead
                        },
                    }) => (
                        <PostItem
                            key={date}
                            slug={slug}
                            background={background}
                            category={category}
                            date={date}
                            timeToRead={timeToRead}
                            title={title}
                            description={description}
                            image={image}
                        />
                    ))}
                </S.ListWrapper>

                {numPages > 1 && (
                    <Pagination
                        isFirst={isFirst}
                        isLast={isLast}
                        currentPage={currentPage}
                        numPages={numPages}
                        prevPage={prevPage}
                        nextPage={nextPage}
                    />
                )}
            </Container>
		</Layout>
	)
}

export const query = graphql`
	query GetPostList($limit: Int!, $skip: Int!) {
		allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }, limit: $limit, skip: $skip) {
			edges {
				node {
					fields {
						slug
					}
					frontmatter {
						background
						category
						description
						date(formatString: "DD [de] MMMM [de] YYYY", locale: "pt-br")
						title
                        image
					}
					timeToRead
				}
			}
		}
	}
`;

export default BlogList;
