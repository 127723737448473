import styled from 'styled-components';
import media from 'styled-media-query';

export const PaginationWrapper = styled.section`
	align-items: center;
	color: var(--background-font);
	display: flex;
	padding: 1.5rem 3rem;
	justify-content: space-between;

	${media.lessThan("large")`
		font-size: .8rem;
		padding: 1rem;
	`}

	a {
		color: var(--background-font);
		text-decoration: none;
		transition: color 0.5s;

		&:hover {
			color: var(--background-auxiliar);
		}
	}
`
