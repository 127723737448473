import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'gatsby';

import getThemeColor from '../../utils/getThemeColor';
import * as S from './styled';

const Pagination = ({ isFirst, isLast, currentPage, numPages, prevPage, nextPage }) => (
	<S.PaginationWrapper>
		{!isFirst && (<Link to={prevPage} cover direction="left" bg={getThemeColor()} duration={0.6}>Página anterior</Link>)}

		{currentPage} de {numPages}

		{!isLast && (<Link to={nextPage} cover direction="right" bg={getThemeColor()} duration={0.6}>Próxima página</Link>)}
	</S.PaginationWrapper>
)

Pagination.propTypes = {
	isFirst: propTypes.bool.isRequired,
	isLast: propTypes.bool.isRequired,
	currentPage: propTypes.number.isRequired,
	numPages: propTypes.number.isRequired,
	prevPage: propTypes.string,
	nextPage: propTypes.string
}

export default Pagination;
